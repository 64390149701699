export function chunk(arr: any[], size: number) {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  );
}

export function keyBy<T extends Record<string, any>, K extends keyof T>(
  array: T[],
  key: K,
): Record<string, T> {
  return array.reduce((result: Record<string, T>, item: T) => {
    const keyValue = String(item[key]);
    result[keyValue] = item;
    return result;
  }, {});
}
