import {
  Money,
  Procurement,
  ProcurementInput,
  ProcurementVendor,
  Warehouse,
} from '@/graphql/types/generated';
import { formatMoney, priceInput, priceOutput } from '../../../utils/PricingIO';
import { keyBy } from '../../../utils/array';

/*
 * This type reflects the UI structure, not GraphQL's.
 *
 * In the `toProcurementInput` function the data is mapped to the appropriate
 * GraphQL Type (ProcurementInput).
 */
export type ProcurementRow = {
  vendorId: string;
  vendorName: string;
  warehouseId: string;
  warehouseName: string;
  purchasePrice?: Money;
};

type PurchasePriceWarehouses = {
  purchasePrice: Money;
  warehouseIds: Set<string>;
};

type ProcurementVendorId = string;

/**
 * Create a {@link ProcurementRow} array from a list of {@link Procurement} records.
 *
 * @param allVendors all ({@link ProcurementVendor}s that currently exist in the system.
 * @param allWarehouses all ({@link Warehouse}s that currently exist in the system.
 * @param procurementRecords the ({@link Procurement} array to map from.
 */
export const createProcurementRowsForUI = (
  allVendors: ProcurementVendor[],
  allWarehouses: Warehouse[],
  procurementRecords: Procurement[],
): ProcurementRow[] => {
  if (procurementRecords.length === 0) {
    return [];
  }

  const procurementMap = procurementRecords.reduce(
    (map, { procurementVendor, warehouse, purchasePrice }) => {
      const price = priceOutput(purchasePrice);
      const key = `${procurementVendor.id}_${formatMoney(price)}`;

      if (map.has(key)) {
        const { warehouseIds } = map.get(key)!;
        warehouseIds.add(warehouse.id);
      } else {
        map.set(key, {
          purchasePrice: price,
          warehouseIds: new Set([warehouse.id]),
        });
      }

      return map;
    },
    new Map<ProcurementVendorId, PurchasePriceWarehouses>(),
  );

  const allVendorsMap = keyBy(allVendors, 'id');
  const allWarehousesMap = keyBy(allWarehouses, 'id');
  const allWarehouseIds = new Set(allWarehouses.map((w) => w.id));

  return [...procurementMap.entries()]
    .flatMap(([key, { purchasePrice, warehouseIds }]) => {
      const procurementVendorId = key.split('_')[0];
      const warehouseIdArray = [...warehouseIds];

      if (
        warehouseIds.size === allWarehouseIds.size &&
        warehouseIdArray.every((w) => allWarehouseIds.has(w))
      ) {
        return [
          {
            vendorId: procurementVendorId,
            vendorName: allVendorsMap[procurementVendorId].name,
            warehouseId: 'ALL',
            warehouseName: 'ALL',
            purchasePrice,
          },
        ];
      }

      return warehouseIdArray.map((warehouseId) => ({
        vendorId: procurementVendorId,
        vendorName: allVendorsMap[procurementVendorId].name,
        warehouseId,
        warehouseName: allWarehousesMap[warehouseId].name,
        purchasePrice,
      }));
    })
    .sort(
      (v1: ProcurementRow, v2: ProcurementRow) =>
        v1.vendorName.localeCompare(v2.vendorName) ||
        v1.warehouseName.localeCompare(v2.warehouseName),
    );
};

/**
 * Helper function to map a {@link ProcurementRow} to a {@link ProcurementInput} array.
 * @param row the {@link ProcurementRow} to map from.
 */
export const toProcurementInput = (
  row: ProcurementRow,
  warehouses: Warehouse[],
): ProcurementInput[] => {
  if (row.warehouseId === 'ALL') {
    return warehouses.map(({ id: warehouseId }) => ({
      procurementVendorId: row.vendorId,
      purchasePrice: priceInput(row.purchasePrice!),
      warehouseId,
    }));
  }

  return [
    {
      procurementVendorId: row.vendorId,
      purchasePrice: priceInput(row.purchasePrice!),
      warehouseId: row.warehouseId,
    },
  ];
};
