import { gql } from 'urql';

export const CREATE_BRAND = gql`
  mutation CreateItemBrand($input: ItemBrandCreateInput!) {
    createItemBrand(input: $input) {
      errors {
        message
      }
      itemBrand {
        id
        name
      }
    }
  }
`;

export const DELETE_BRAND = gql`
  mutation DeleteItemBrand($input: ItemBrandDeleteInput!) {
    deleteItemBrand(input: $input) {
      errors {
        message
      }
    }
  }
`;

export const UPDATE_BRAND = gql`
  mutation UpdateItemBrand($input: ItemBrandUpdateInput!) {
    updateItemBrand(input: $input) {
      errors {
        message
      }
      itemBrand {
        id
        name
      }
    }
  }
`;

export const CREATE_INVENTORY_ITEM = gql`
  mutation CreateInventoryItem($item: InventoryItemInput!) {
    createInventoryItem(item: $item) {
      id
      name
    }
  }
`;

export const CREATE_NON_INVENTORY_ITEM = gql`
  mutation CreateNonInventoryItem($item: NonInventoryItemInput!) {
    createNonInventoryItem(item: $item) {
      id
      name
    }
  }
`;

export const DISCONTINUE_ITEM = gql`
  mutation DiscontinueItem($id: ID!, $input: [DiscontinueWarehouseInput]!) {
    discontinueWarehouseItem(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const IMPORT_PRICING_RULES = gql`
  mutation ImportPriceRulesCsv($input: ImportPriceRulesCsvInput!) {
    importPriceRulesCsv(input: $input) {
      clientMutationId
      errors
    }
  }
`;

export const IMPORT_PROCUREMENT_RECORDS = gql`
  mutation ImportProcurementRecords($input: [ImportProcurementInput!]!) {
    importProcurementRecords(input: $input)
  }
`;

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem($id: ID!, $item: InventoryItemInput!) {
    updateInventoryItem(id: $id, item: $item) {
      id
      name
    }
  }
`;

export const UPDATE_NON_INVENTORY_ITEM = gql`
  mutation UpdateNonInventoryItem($id: ID!, $item: NonInventoryItemInput!) {
    updateNonInventoryItem(id: $id, item: $item) {
      id
      name
    }
  }
`;

export const UPSERT_LOCATION_ITEM = gql`
  mutation UpsertLocationItem($input: LocationItemInput!) {
    upsertLocationItem(input: $input) {
      locationId
      itemId
    }
  }
`;

export const REMOVE_LOCATION_ITEM = gql`
  mutation RemoveLocationItem($input: LocationItemInput!) {
    removeLocationItem(input: $input)
  }
`;

export const UPDATE_ITEM_PRICES = gql`
  mutation UpdateItemPrices($input: UpdateItemPricesInput!) {
    updateItemPrices(input: $input) {
      result {
        ... on ItemPricesSuccess {
          updatedItemsCount
          updatedWarehouseItemsCount
        }
        ... on ErrorsResult {
          errors {
            head {
              message
            }
            tail {
              message
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_WAREHOUSE_LEAD_TIME = gql`
  mutation UpdateWarehousesLeadTime(
    $input: UpdateWarehousesLeadTimeMutationInput!
  ) {
    updateWarehousesLeadTime(input: $input) {
      clientMutationId
    }
  }
`;
