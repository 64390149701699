import {
  Warehouse,
  InventoryItem,
  NonInventoryItem,
  InventoryWarehouseAssociationInput,
  NonInventoryWarehouseAssociationInput,
  Money,
  ProductInventory,
  SupplyItem,
} from '@/graphql/types/generated';
import {
  WarehouseAssociation,
  getVendorId,
  getVendorName,
  getVendorWarehousename,
} from '@/types';
import { UpsertItemForm } from '../../UpsertItem/types/UpsertItemForm';
import { priceInput, priceOutput } from '@/utils/PricingIO';

/* Note: Updating this to reflect the UI's structure, not the GraphQL structure
 * Within the `to___WarehouseAssociationInput` functions is the data associated
 * to the appropriate GraphQL Type
 * (InventoryWarehouseAssociationInput | NonInventoryWarehouseAssociationInput)
 */
export type WarehousesRow = {
  id: string;
  name: string;
  enabled: boolean;
  hasSample: boolean;
  salePrice?: Money;
  vendorId: string;
  vendorName: string;
  warehouseName: string;
  saved: boolean;
  inventory: number;
  sample?: SupplyItem;
  fulfillmentWarehouseIds: string[];
};

/**
 * Helper map function to convert a {@link WarehousesRow} to {@link InventoryWarehouseAssociationInput}
 * @param row the {@link WarehousesRow} to map from
 */
export const toInventoryWarehouseAssociationInput = (
  row: WarehousesRow,
  disablePriceEditing: boolean,
): InventoryWarehouseAssociationInput => {
  return {
    procurementVendorId: row.vendorId === '' ? null : row.vendorId,
    salePrice: disablePriceEditing
      ? typeof row.salePrice !== 'undefined'
        ? priceInput(row.salePrice)
        : null
      : priceInput(row.salePrice),
    hasSample: row.hasSample,
    warehouseId: row.id,
  };
};

/**
 * Helper map function to convert a {@link WarehousesRow} to {@link NonInventoryWarehouseAssociationInput}
 * @param row the {@link WarehousesRow} to map from
 */
export const toNonInventoryWarehouseAssociationInput = (
  row: WarehousesRow,
  disablePriceEditing: boolean,
): NonInventoryWarehouseAssociationInput => {
  return {
    vendorId: row.vendorId,
    salePrice: disablePriceEditing
      ? typeof row.salePrice !== 'undefined'
        ? priceInput(row.salePrice)
        : null
      : priceInput(row.salePrice),
    hasSample: row.hasSample,
    warehouseId: row.id,
  };
};

/**
 * Creates an array of {@link WarehousesRow}'s from a list of
 * {@link Warehouse} and a list of {@link WarehouseAssociation}
 * for use in {@link ItemForm} that matches by ID
 *
 * @param warehouses the array of ({@link Warehouse} to map from
 * @param warehouseAssociation the array of ({@link WarehouseAssociation} to match to
 */
export const createWarehouseRowsForUI = (
  warehouses: Warehouse[],
  { warehouseAssociation }: UpsertItemForm | (InventoryItem | NonInventoryItem),
  productInventory?: ProductInventory[],
): WarehousesRow[] => {
  if (!warehouses) {
    return [];
  }
  // let's drill down warehouseAssociation to a definitive type

  return warehouses
    .sort((left, right) => {
      return left.name.localeCompare(right.name);
    })
    .map((warehouse) => {
      const found =
        (warehouseAssociation as WarehouseAssociation[]).find(
          (v) => warehouse.id === v?.warehouse?.id,
        ) ?? undefined;

      let foundProductInventory: ProductInventory | undefined;

      if (productInventory) {
        foundProductInventory = (
          (productInventory as ProductInventory[]) || undefined
        ).find((v) => warehouse.id === v?.warehouse?.id);
      }

      const rawSalePrice = found?.salePrice ?? undefined;
      const salePrice =
        rawSalePrice === undefined ? undefined : priceOutput(rawSalePrice);

      const vendorId = found === undefined ? '' : getVendorId(found) ?? '';
      const vendorName = found === undefined ? '' : getVendorName(found) ?? '';
      const warehouseName =
        found === undefined ? '' : getVendorWarehousename(found) ?? '';

      const hasSample = found === undefined ? false : !!found.sample;

      const enabled = !!salePrice && vendorId !== '';

      return {
        enabled,
        id: warehouse.id,
        name: warehouse.name,
        ...found,
        salePrice,
        hasSample,
        vendorId,
        vendorName,
        warehouseName,
        saved: vendorId !== '',
        inventory: foundProductInventory?.availableCount,
        fulfillmentWarehouseIds:
          found?.fulfillmentWarehouses?.map((fw) => fw.warehouse?.id) ?? [],
      };
    })
    .filter((wr) => wr) as WarehousesRow[];
};

export const getGlobalFulfillmentStatus = (row: WarehousesRow) => {
  return row.fulfillmentWarehouseIds.length
    ? !row.fulfillmentWarehouseIds.includes(row.id)
    : false;
};
